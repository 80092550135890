<template>
  <div @click="$emit('click')" :class="cssClasses.bgCover">
    <div
      :class="cssClasses.wrapper"
      :style="{
        justifyContent: bottomText && 'flex-end',
        ...(isAcademyEntrepreneurship ? infoCardStyles : {}),
      }"
    >
      <VueText
        v-if="!isAcademyEntrepreneurship"
        color="white-100"
        :class="cssClasses.title"
        :sizeLevel="sizeLevel"
        weightLevel="5"
        >{{ title }}</VueText
      >
      <VueBadge v-if="!!marathon" :radius="25">
        <a
          v-on:click="playNow()"
          :class="cssClasses.badge"
          style="color: #232323; text-decoration: none;"
        >
          <VueText sizeLevel="4" weightLevel="5">Hemen Oyna</VueText>
        </a>
      </VueBadge>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueBadge from '@/components/shared/VueBadge/VueBadge.vue';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesPlayAndWin from '@/router/routes/secure/RoutesPlayAndWin';
import StorageHelper from '@/utils/storageHelper';
import gtmUtils from '@/mixins/gtmUtils.js';
export default {
  name: 'BrandInfoCard',
  mixins: [gtmUtils],
  props: {
    title: {
      type: String,
      required: true,
    },
    sizeLevel: {
      type: String,
    },
    bgColor: {
      type: String,
    },
    iframeGame: {
      type: Boolean,
    },
    bottomText: {
      type: Boolean,
      default: false,
    },
    marathon: {
      type: Object,
    },
    academyParentTypeId: {
      type: Number,
    },
    infoCardStyles: {
      type: Object,
    },
  },
  methods: {
    async playNow() {
      if (this.marathon?.marathonName === 'Ödül Yağmuru') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('odül_yagmuru', {
          location: 'oyna_kazan',
        });
      }
      if (this.marathon?.marathonUrl) {
        new StorageHelper({ id: 'PlayableInfo' }).set(this.marathon);
      }
      event.stopPropagation();
      // bu yarışmanın surveyIdsi prop olarak komponente bağlanması gerek(oyna kazan ana ekranda ek servis lazım), tanımlı yarışma yoksa hemen oyna linki gözükmemeli
      const route = this.marathon?.marathonUrl
        ? `${RoutesRoot.Secure.path}/${RoutesSecure.WeeklyPlayable.path}`
        : `${RoutesRoot.Secure.path}/${RoutesSecure.PlayAndWin.path}/${RoutesPlayAndWin.PlayAndWinDetail.path}`;

      this.$router.push(this.iframeGame ? { path: route, query: { iframeGame: 1 } } : route);
    },
  },
  computed: {
    cssClasses() {
      const wrapper = this.$style['info-card-wrapper'];
      const title = this.$style['title'];
      const badge = this.$style['badge'];
      const bgCover = [this.$style['bg-cover']];
      bgCover.push(this.$style[`bg-cover-${this.bgColor}`]);
      return { wrapper, title, badge, bgCover };
    },
    isAcademyEntrepreneurship() {
      return this.academyParentTypeId == 452;
    },
  },
  components: {
    VueText,
    VueBadge,
  },
};
</script>
<style lang="scss" module>
.bg-cover {
  position: relative;
  height: 0;
  border-radius: palette-space-level(5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 100%;
  background-size: cover;
}
.bg-cover-blue {
  background-image: url('~@/assets/bg/blue-bg.png');
}
.bg-cover-yellow {
  background-image: url('~@/assets/bg/yellow-bg.png');
}
.bg-cover-green {
  background-image: url('~@/assets/bg/green-bg.png');
}
.bg-cover-red {
  background-image: url('~@/assets/bg/red-bg.png');
}
.bg-cover-peach {
  background-image: url('~@/assets/bg/peach-bg.png');
}
.bg-cover-orange {
  background-image: url('~@/assets/bg/orange-bg.png');
}
.bg-clickable {
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
}
.info-card-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  border-radius: palette-space-level(5);
  flex-direction: column;
  justify-content: space-between;
  padding: palette-space-level(10);
  background-size: cover;
  .title {
    max-width: 110px;
  }
  .badge {
    padding: palette-space-level(10) palette-space-level(15);
    @media screen and (max-width: 320px) {
      padding: palette-space-level(10);
      p {
        font-size: 12px;
      }
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
